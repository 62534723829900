import Vue from 'vue';
import { Message } from 'element-ui';

Vue.prototype.$showSuccess = function (value = '', duration = 5000) {
  if (value !== '') {
    var message = value;
    this.$message({
      message: message,
      type: 'success',
      showClose: true,
      duration: duration,
    });
  }
};

Vue.prototype.$showError = function (value = '', duration = 5000) {
  if (value !== '') {
    var message = value;
    Message.closeAll();
    this.$message.error({
      message: message,
      type: 'error',
      showClose: true,
      duration: duration,
    });
  }
};
