import { Loading } from 'element-ui';
export const LoadingUtil = {
  loading: '',
  showLoading: function() {
    // 修改loading位置 表格内居中
    this.loading = Loading.service({
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  },
  hideLoading: function() {
    if (this.loading) {
      this.loading.close();
    }
  }
};
