import router from "./index";
import { getToken } from '@/common/utils/common/auth'; // 验权
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式

router.beforeEach((to, from, next) => {
  toPage();
  function toPage() {
    NProgress.start();

    // 内页拦截
    if (to.path === '/login') {
      if (getToken()) {
        next({
          path: '/'
        });
      } else {
        next();
        NProgress.done();
      }
      return;
    }

    if (getToken()) {
      if (to.path === '/login') {
        next({
          path: '/'
        });
      } else {
        next();
      }
    } else {
      next('/login');
      NProgress.done();
    }
  }
})

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
