<script>
// template 不方便递归，用jsx生成菜单
export default {
  name: "md-menu",
  props: {
    menuData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  methods: {
    createMenuItem(menu) {
      return menu.map(({ code, name, children, iconClass }) =>
        children.length === 0 ? (
          <el-menu-item index={code}>
            <i class={iconClass}></i>
            <span class="title">{name}</span>
          </el-menu-item>
        ) : (
          <el-submenu index={code}>
            <template slot="title">
              <div>
                <i class={iconClass}></i>
                <span class="title">{name}</span>
              </div>
            </template>
            {this.createMenuItem(children)}
          </el-submenu>
        )
      );
    },
  },
  render() {
    return (
      <el-menu
        style="height:100%"
        default-active="activeMenu"
        background-color="transparent"
        text-color="#c1cbd8"
        opacity='1'
        active-text-color="#5a9cf8"
        router
      >
        {this.createMenuItem(this.menuData)}
      </el-menu >
    );
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 13px;
  // font-weight: bold;
}

.title2 {
  font-size: 13px;
}
</style>
