<template>
  <div>
    <div class="wrapper" style="height: 80px;">
      <div class="header-box">
        <div class="header">
          <div class="header-front">
            <el-image src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/1090-zuqiuxing.png"
              style="width: 50px;height: 50px;margin-left: 20px;"></el-image>
            <div class="lf projectName" style="margin-left:20px;font-size:20px;color: #fff;">足球行-球场管理系统</div>
          </div>
          <div class="bottom-info">
            <img :src="userInfo.personPic" alt="" />
            <div>
              <i class="zi zi zi_bell mr-8 zi_aax" style="color: #fff;" @click="message()"></i>
              <el-dropdown @command="handleCommand">
                <i class="zi zi_usercircle zi_aax wh"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="leftMenuBox">
        <el-aside :width="width" style="flex: 1">
          <Menu :menuData="menus"></Menu>
        </el-aside>
      </div>
      <div class="content">
        <transition name="move" mode="out-in">
          <router-view>
          </router-view>
        </transition>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import Menu from './components/Menu.vue';
// import Navbar from './components/Navbar.vue';
export default {
  name: 'layout',
  components: {
    Menu,
  },
  data() {
    return {
      width: '240px',
      search: '',
      show: 1,
      isCollapse: false,
      loading: true,
      imageUrl:
        'https://football-go.oss-cn-hangzhou.aliyuncs.com/zhengwu/longgucun.png',
      menus: [
        {
          code: 'DataStatistic',
          children: [],
          name: '球场报告',
          iconClass: 'zi zi_tmCvcsa mr-2',
        },
        {
          code: 'BasicInfo',
          children: [],
          name: '控制台',
          iconClass: 'zi zi_warehouse mr-2',
        },
        {
          code: 'fieldDetail',
          children: [],
          name: '球场订单',
          iconClass: 'zi zi_futbol mr-2',
        },
        {
          code: 'FieldOrder',
          children: [],
          name: '订单列表',
          iconClass: 'zi zi_futbol mr-2',
        },
        {
          code: 'FieldInfo',
          children: [],
          name: '球场信息',
          iconClass: 'zi zi_infocircle mr-2',
        },
        {
          code: 'VenueManage',
          children: [],
          name: '场地信息',
          iconClass: 'zi zi_listSquare mr-2',
        },
        {
          code: 'MemberSet',
          children: [],
          name: '会员设置',
          iconClass: 'zi zi_diamond mr-2',
        },
        {
          code: 'FieldMoney',
          children: [],
          name: '球场钱包',
          iconClass: 'zi zi_wallet mr-2',
        },
        {
          code: 'Administrator',
          children: [],
          name: '多管理员',
          iconClass: 'zi zi_boxes mr-2',
        },
        {
          code: 'OrderServe',
          children: [],
          name: '订单管理',
          iconClass: 'zi zi_fileBook mr-2',
        },
        // {
        //   code: 'EventManage',
        //   name: '赛事管理',
        //   iconClass: 'zi zi_chartpie mr-2',
        //   children: [
        //       {
        //       code: 'MatchList',
        //       children: [],
        //       name: '赛事列表',
        //       iconClass: 'zi zi_copyright mr-2',
        //     },
        // {
        //   code: 'InfoList',
        //   children: [],
        //   name: '基本信息',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'EventManage',
        //   children: [],
        //   name: '赛事信息',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'GroupLists',
        //   children: [],
        //   name: '组别管理',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'TeamControl',
        //   children: [],
        //   name: '球队管理',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'TeamList',
        //   children: [],
        //   name: '球员管理',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'MatchManage',
        //   children: [],
        //   name: '赛程信息',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'CourseManage',
        //   children: [],
        //   name: '赛况管理',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        // {
        //   code: 'EventList',
        //   children: [],
        //   name: '赛事榜单',
        //   iconClass: 'zi zi_copyright mr-2',
        // },
        //   ],
        // },
      ],
    };
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('user_info'));
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      }
    },
    logout() {
      this.$confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('user_info');
        localStorage.removeItem('fieldId');
        localStorage.removeItem('currentField');
        // this.$router.push({ name: 'login' });
        location.reload();
      });
    },

    //消息弹窗
    message() {
      this.$confirm('您暂无消息通知', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: "confirm",
      });
    },
    contact() {
      this.$confirm('技术支持请联系：wanmminban@tom.com', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: "confirm",
      });
    },
    showToggle() {
      if (this.show == 1) {
        this.show = 0;
      } else {
        this.show = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .header-box {
    opacity: 0.84;
    background-image: linear-gradient(1deg, #51beff 0%, rgba(0, 159, 255, 0.45) 1%, rgba(0, 132, 211, 0.41) 4%, rgba(0, 126, 201, 0.4) 9%, rgba(0, 0, 0, 0) 100%);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 80px;
      border: none;
      line-height: 80px;

      .header-front {
        display: flex;
        align-items: center;
      }


      .logo {
        width: 50px;
        height: 50px;
        margin: 15px 0 0 15px;
        background-size: contain;
      }

      .projectName {
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 80px;
      }
    }
  }
}

.content-box {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;

  .leftMenuBox {
    // height: calc(100vh-20px);
    box-sizing: border-box;
    overflow: auto;
    // width: 200px;
    flex-shrink: 0;
    background-image: linear-gradient(270deg, #51beff 0%, rgba(0, 159, 255, 0.45) 1%, rgba(0, 132, 211, 0.41) 4%, rgba(0, 0, 0, 0) 100%);
    box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.25);
  }

  .content {
    // display: flex;
    flex-direction: row;
    flex: 1;
    flex-basis: auto;
    box-sizing: border-box;
    min-width: 0;
    overflow: auto;
    padding: 20px;
  }
}

.pageMain {
  width: 100%;
  margin-top: 50px;
}

.header {
  padding: 20px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.left-menu-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: #334054;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

/deep/.el-submenu .el-menu-item {
  // width: 200px;
  // background-color: #1f2d3d !important
}

/deep/.el-submenu,
/deep/.el-menu-item {
  // width: 230px;
}

/deep/ .el-submenu__title i,
/deep/.el-menu-item i {
  color: #c1cbd8;
}

/deep/.el-submenu .el-menu-item {
  &:hover {
    background-image: linear-gradient(270deg, #51beff 0%, rgba(0, 159, 255, 0.45) 1%, rgba(0, 132, 211, 0.41) 4%, rgba(0, 0, 0, 0) 100%);
  }
}


.left-menu-top {
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  // background-color: #334054;
}

.left-match {
  display: flex;
  justify-content: center;
}

.bottom-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2vh 0;
  margin-right: 20px;
}

.bottom-info img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
}

.slogin {
  color: #fff;
  font-size: 23px;
  font-family: 'Lucida Calligraphy', cursive, serif, sans-serif;
}

.title {
  margin-left: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: darkgray;
}

.bottom-title {
  font-size: 17px;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  transition: width 0.28s;
}

.foot {
  padding-top: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: 'Lucida Calligraphy', cursive, serif, sans-serif;
}

.menu-bottom {
  display: flex;
  justify-content: center;
  color: #0f58a3;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
  font-family: '微软雅黑';
}
</style>
