import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../common/views/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../components/pages/Login.vue")
  },
  {
    path: '/',
    component: () => import('../common/views/layout/Layout.vue'),
    redirect: '/dataStatistic',
    children: [
      {
        path: '/dataStatistic',
        name: 'DataStatistic',
        component: () => import('../views/statistic/DataStatistic.vue')
      }
    ]
  },
  {
    path: '/informatation',
    redirect: '/informatation',
    component: layout,
    children: [
      {
        path: '/BasicInfo',
        name: 'basicinfo',
        component: () => import('../views/informatation/BasicInfo.vue'),
        meta: { title: '订单管理' },
      },
      {
        path: '/PlanTemplate',
        name: 'planTemplate',
        component: () => import('../views/informatation/PlanTemplate.vue'),
      },
    ]
  },
  {
    path: '/statistic',
    redirect: '/statistic/DataStatistic',
    component: layout,
    children: [
      {
        path: '/DataStatistic',
        name: 'datastatistic',
        component: () => import('../views/statistic/DataStatistic.vue'),
      },
    ]
  },
  {
    path: '/fieldDetail',
    redirect: '/fieldDetail/fieldDetail',
    component: layout,
    children: [
      {
        path: '/fieldDetail',
        name: 'fieldDetail',
        component: () => import('../views/fieldDetail/fieldDetail.vue'),
      },
    ]
  },
  {
    path: '/FieldOrder',
    redirect: '/fieldDetail/FieldOrder',
    component: layout,
    children: [
      {
        path: '/FieldOrder',
        name: 'fieldOrder',
        component: () => import('../views/fieldDetail/FieldOrder.vue'),
      },
    ]
  },
  {
    path: '/FieldInfo',
    redirect: '/FieldInfo/FieldInfo',
    component: layout,
    children: [
      {
        path: '/FieldInfo',
        name: 'FieldInfo',
        component: () => import('../views/FieldInfo/FieldInfo.vue'),
      },
    ]
  },
  {
    path: '/VenueManage',
    redirect: '/VenueManage/VenueManage',
    component: layout,
    children: [
      {
        path: '/VenueManage',
        name: 'VenueManage',
        component: () => import('../views/VenueManage/VenueManage.vue'),
      },
    ]
  },
  {
    path: '/memberSet',
    redirect: '/memberSet/MemberSet',
    component: layout,
    children: [
      {
        path: '/MemberSet',
        name: 'memberSet',
        component: () => import('../views/memberSet/MemberSet.vue'),
      },
    ]
  },
  {
    path: '/administrator',
    redirect: '/administrator/Administrator',
    component: layout,
    children: [
      {
        path: '/Administrator',
        name: 'Administrator',
        component: () => import('../views/administrator/Administrator.vue'),
      },
    ]
  },
  {
    path: '/orderServe',
    redirect: '/orderServe/OrderServe',
    component: layout,
    children: [
      {
        path: '/OrderServe',
        name: 'orderServe',
        component: () => import('../views/orderServe/OrderServe.vue'),
      },
    ]
  },
  {
    path: '/fieldMoney',
    redirect: '/fieldMoney/FieldMoney',
    component: layout,
    children: [
      {
        path: '/FieldMoney',
        name: 'fieldMoney',
        component: () => import('../views/fieldMoney/FieldMoney.vue'),
      },
    ]
  },
  {
    path: '/eventManage',
    redirect: '/eventManage/EventManage',
    component: layout,
    meta: { title: '赛事系统' },
    children: [
      {
        path: '/MatchList',
        name: 'matchList',
        component: () => import('../views/eventManage/matchList/MatchList'),
        meta: { title: '赛事列表' },
      },
      {
        path: '/MatchDetail',
        name: 'matchDetail',
        component: () => import('../views/eventManage/matchDetail/MatchDetail'),
        meta: { title: '赛事详情' },
      },
      {
        path: '/PlayerManage',
        name: 'PlayerManage',
        component: () => import('../views/eventManage/matchDetail/playerManage/PlayerMange'),
        meta: { title: '球员管理' },
      },
      {
        path: '/Outslists',
        name: 'OutLists',
        component: () => import('../views/eventManage/matchDetail/courseManage/OutsLists'),
        meta: { title: '赛况管理' },
      },
      // {
      //   path: '/GroupLists',
      //   name: 'grouplists',
      //   component: () => import('../views/eventManage/groupList/GroupLists'),
      //   meta: { title: '组别管理' },
      // },
      // {
      //   path: '/EventManage',
      //   name: 'eventManage',
      //   component: () => import('../views/eventManage/EventManage.vue'),
      //   meta: { title: '赛事信息' },
      // },
      // {
      //   path: '/TeamControl',
      //   name: 'teamControl',
      //   component: () => import('../views/eventManage/teamControl/TeamControl'),
      //   meta: { title: '球队管理' },
      // },
      // {
      //   path: '/MatchManage',
      //   name: 'matchManage',
      //   component: () => import('../views/eventManage/matchManage/MatchManage'),
      //   meta: { title: '赛程信息' },
      // },
      // {
      //   path: '/EventList',
      //   name: 'eventList',
      //   component: () => import('../views/eventManage/eventList/EventList'),
      //   meta: { title: '赛事榜单' },
      // },
      // {
      //   path: '/InfoList',
      //   name: 'infoList',
      //   component: () => import('../views/eventManage/InfoList/InfoList'),
      //   meta: { title: '基本信息' },
      // },
    
      // {
      //   path: '/TeamList',
      //   name: 'teamList',
      //   component: () => import('../views/eventManage/playerManage/TeamList'),
      //   meta: { title: '球队展示' },
      // },
      // {
      //   path: '/CourseManage',
      //   name: 'courseManage',
      //   component: () => import('../views/eventManage/matchDetail/CourseManage/CourseManage'),
      //   meta: { title: '赛况管理' },
      // },
    
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
