import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http';
import './index.css'
import "./style/index.scss"

import '@/router/routerFilter';

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'
Vue.use(ElementUI, { size: 'small' })

Vue.use(VueQuillEditor)
Vue.use(http);

import '@/common/utils/common/utils.js'

Vue.config.productionTip = false
require('../node_modules/font-awesome/css/font-awesome.min.css');
require('../node_modules/zico/css/zico.min.css');
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
