import axios from "axios";
import app from "./main";
import { LoadingUtil } from '@/common/utils/common/LoadingUtil';
import { removeToken } from '@/common/utils/common/auth';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5 * 1000,
  validateStatus(status) {
    return status >= 200 && status < 300;
  },
});

http.interceptors.request.use((config) => {
  // 请求头添加用户token
  if (localStorage.getItem("Authorization")) {
    config.headers = {
      Authorization: localStorage.getItem("Authorization"),
      fieldId: localStorage.getItem('fieldId')
    };
  }
  return config;
});

// 这里校验登录状态
http.interceptors.request.use((config) => {
  // 检测登陆凭证是否过期
  const expiresAt = localStorage.getItem("expiresAt"); // eslint-disable-line
  if (parseInt(expiresAt, 10) < Date.now()) {
    app.$notify.error({
      message: "登陆超时",
      description: "您的登陆凭证已过期（3秒后跳转登陆页）",
    });
	removeToken();
    setTimeout(() => location.reload(), 3000); // eslint-disable-line
    throw new Error("登陆凭证已过期");
  }
  LoadingUtil.showLoading();
  return config;
});

http.interceptors.response.use(
  (result) => {
    const { data } = result;
    // 单独处理下载文件流
    // if (headers["content-type"] !== "application/json; charset=utf-8")
    //   return result;

    LoadingUtil.hideLoading();

    if (data.code === 401) {
      // todo 跳转登录
      app.$notify.error({
        title: "信息过期",
        message: `登录信息过期，稍后跳转登录页面`,
      });
      localStorage.removeItem('Authorization');
      localStorage.removeItem('user_info');
      setTimeout(() => {
        location.reload();
      }, 1000);
      return;
    }

	// 请求错误提示
    if (!result.data.isSuccess) {
      app.$showError(result.data.msg);
    }

    return {
      res: data
    };
  },
  (error) => {
    if (!error.response) return Promise.reject(error);
    const { status, data } = error.response;
    app.$notify.error({
      title: "网络请求错误",
      message: `接口请求失败，状态码：${status}`,
    });
    const ret = {};
    if (data) {
      ret.code = data.code;
      ret.message = data.msg;
    } else {
      ret.code = status;
      ret.message = "网络请求错误";
    }
    return Promise.reject(ret);
  }
);

const {
  get: _get,
  post: _post,
  delete: _delete,
  put: _put,
  patch: _patch,
} = http;

export { _get, _post, _put, _delete, _patch };
export default {
  install(Vue) {
    Vue.prototype.$http = http;
  },
};
